:root {
  --nav-width: 200px;
  overflow: hidden;
}

.App {
  text-align: center;
}

.nav-container {
  width: var(--nav-width) !important;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

h1 {
  color: #618d93;
}

.body {
  margin-left: var(--nav-width);
  background-color: white;
  color: #545454;
  height: 100vh;
  text-align: center;
  width: auto !important;
  flex-direction: column !important;
  align-items: center;
  overflow: auto;
  flex-wrap: nowrap !important;
}

.img {
  width: var(--nav-width);
  padding: 32px;
}

img {
  width: 100%;
}
